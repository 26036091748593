@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

body div#root {
  width: 100%;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

button p,
button div {
  margin: 0!important;
}

button[type="button"] {
  appearance: none;
  -webkit-appearance: none;
}

/*button + button {
  margin-left: 20px;
}*/

.reactabular-table {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-collapse: collapse;
  border-spacing: 0;
}

.reactabular-table thead,
.reactabular-table tfoot {
  background-color: #efefef;
  color: #333;
}

.reactabular-table thead {
  border-bottom: 1px solid #e2e2e2;
}

.reactabular-table tfoot {
  border-top: 1px solid #e2e2e2;
}

.reactabular-table th,
.reactabular-table td {
  border-left: 1px solid #e2e2e2;
  border-width: 0 0 0 1px;
  margin: 0;
  overflow: visible;
  padding: 0.5em 1em;
}

.reactabular-table tbody {
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
}

.reactabular-table tbody tr:hover {
  background-color: #dbebf9;
  cursor: pointer;
}

.reactabular-table tbody tr.selected-row {
  background-color: #2e82e4;
  color: #fff;
}

.reactabular-table tbody tr.virtual-sample {
  opacity: 0.3;
}

.sort-container > .sort.sort-asc::after {
  content: '\25BC';
  padding-left: 0.5em;
}

.sort-container > .sort.sort-desc::after {
  content: '\25B2';
  padding-left: 0.5em;
}

.sort-container > .sort.sort-none::after {
  content: '\21D5';
  padding-left: 0.5em;
  font-size: 1em;
}

.sort-container > .sort-order {
  display: none;
}

.search-container > div {
  display: flex;
  align-items: center;
}

.search-container input,
.search-container select {
  padding: 0.5em 0.75em;
  font-size: 14px;
  line-height: 1.25em;
  border: 1px solid #aaaaaa;
  background-color: #fff;
  outline: none;
  border-image: none;
  height: 35px;
}

.search-container select {
  padding: 0.5em 0.25em;
  border-right: none;
  border-radius: 4px 0 0 4px;
  background-color: #f6f6f6;
}

.search-container input {
  border-radius: 0 4px 4px 0;
}

.search-container .icon-button {
  height: 25px;
  width: 25px;
  margin-left: 5px;
  color: #fff!important;
  background-color: #589ceb;
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out;
}

.search-container .icon-button:hover {
  opacity: 0.7;
  -webkit-transform: none;
          transform: none;
  color: #fff;
}

.rodal-dialog {
  display: flex;
  flex-direction: column;
}

.rodal-dialog .header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.rodal-dialog .body {
  flex: 1 1;
  overflow-y: auto;
}

.rodal-dialog .footer {
  border-top: 1px solid #eee;
  margin-top: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.rodal-dialog .footer.left {
  justify-content: flex-start;
}

/*.form input[type=text],
.form input[type=password],
.form select {
  padding: .25rem .5rem;
  font-size: 14px;
  line-height: 1.25;
}*/

/*.form input[type=text],
.form input[type=password],
.form select,
.form input[type=radio],
.form textarea,
.form-control {
  transition: all 0.30s ease-in-out;
  outline: none;
  padding: 6px 12px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
}*/

/*.form-control {
  margin-bottom: 15px;
}*/

/*.form input[type=text]:focus,
.form input[type=password]:focus,
.form select:focus,
.form input[type=radio]:focus,
.form textarea:focus,
.form-control:focus {
  box-shadow: 0 0 5px rgba(37, 197, 255, 1);
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(37, 197, 255, 1);
}*/

/*.form-control:focus {
  margin-bottom: 15px;
}*/



/*.form .form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-height: 2rem;
  transition: all .3s ease-in-out;
}

.form .form-group.top-label {
  align-items: flex-start;
}

.form .form-group.vertical {
  flex-direction: column;
  align-items: flex-start;
}*/

/*.form .form-group.vertical input[type=text],
.form .form-group.vertical input[type=password],
.form .form-group.vertical textarea {
  width: 100%;
}*/

/*.form .form-group > label {
  width: 200px;
  margin-right: 10px;
  text-align: left;
  font-weight: bold;
}*/

/*.form .form-group .field-error {
  color: #EF4836;
  padding: .5rem;
}*/

/*.form .form-section {
  font-size: 1.5rem;
  font-weight: bold;
  margin: .5rem 0;
}*/

/*.form .form-dependent-fields {
  border-left: 2px dotted #AAAAAA;
  margin-left: 5px;
}*/

/*.form .form-dependent-fields > .label {
  font-style: italic;
  padding: 5px;
  color: #555;
}*/

.form .field-actions button {
  border: 0;
  background: transparent;
  padding: 5px;
  margin: 2px;
  opacity: .25;
  cursor: pointer;
  transition: all .2s ease-in-out;
  font-size: 1em;
}

/* .form .form-group:hover .field-actions button {
  opacity: 1;
} */

.form .field-actions .history-button:hover,
.form .field-actions .reject-button:hover,
.form .field-actions .fix-button:hover,
.form .field-actions .accept-button:hover,
.form .field-actions .correct-note-button:hover  {
  opacity: 1;
}

.form .field-actions .fix-button:hover{
  color: #89C4F4;
}
.form .field-actions .reject-button:hover {
  color: #EF4836;
}

.form .field-actions .accept-button:hover {
  color: #5cb85c;
}

.form .field-actions .correct-note-button:hover {
  color: #dd9c45;
}

.form .form-group-wrapper {
  padding: 5px 10px;
  border-left: 5px solid transparent;
}

.form .dirty-field {
  border-left: 5px solid #89C4F4;
  background-color: rgba(137, 196, 244, 0.1);
}

.form .rejected-field {
  border-left: 5px solid #EF4836;
  background-color: rgba(239, 72, 54, 0.1);
}

.form .corrected-field {
  border-left: 5px solid #FFCC00;
  background-color: rgba(255, 204, 0, 0.3);
}

button:focus {
  outline: none;
}

