/*.form input[type=text],
.form input[type=password],
.form select {
  padding: .25rem .5rem;
  font-size: 14px;
  line-height: 1.25;
}*/

/*.form input[type=text],
.form input[type=password],
.form select,
.form input[type=radio],
.form textarea,
.form-control {
  transition: all 0.30s ease-in-out;
  outline: none;
  padding: 6px 12px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
}*/

/*.form-control {
  margin-bottom: 15px;
}*/

/*.form input[type=text]:focus,
.form input[type=password]:focus,
.form select:focus,
.form input[type=radio]:focus,
.form textarea:focus,
.form-control:focus {
  box-shadow: 0 0 5px rgba(37, 197, 255, 1);
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(37, 197, 255, 1);
}*/

/*.form-control:focus {
  margin-bottom: 15px;
}*/



/*.form .form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-height: 2rem;
  transition: all .3s ease-in-out;
}

.form .form-group.top-label {
  align-items: flex-start;
}

.form .form-group.vertical {
  flex-direction: column;
  align-items: flex-start;
}*/

/*.form .form-group.vertical input[type=text],
.form .form-group.vertical input[type=password],
.form .form-group.vertical textarea {
  width: 100%;
}*/

/*.form .form-group > label {
  width: 200px;
  margin-right: 10px;
  text-align: left;
  font-weight: bold;
}*/

/*.form .form-group .field-error {
  color: #EF4836;
  padding: .5rem;
}*/

/*.form .form-section {
  font-size: 1.5rem;
  font-weight: bold;
  margin: .5rem 0;
}*/

/*.form .form-dependent-fields {
  border-left: 2px dotted #AAAAAA;
  margin-left: 5px;
}*/

/*.form .form-dependent-fields > .label {
  font-style: italic;
  padding: 5px;
  color: #555;
}*/

.form .field-actions button {
  border: 0;
  background: transparent;
  padding: 5px;
  margin: 2px;
  opacity: .25;
  cursor: pointer;
  transition: all .2s ease-in-out;
  font-size: 1em;
}

/* .form .form-group:hover .field-actions button {
  opacity: 1;
} */

.form .field-actions .history-button:hover,
.form .field-actions .reject-button:hover,
.form .field-actions .fix-button:hover,
.form .field-actions .accept-button:hover,
.form .field-actions .correct-note-button:hover  {
  opacity: 1;
}

.form .field-actions .fix-button:hover{
  color: #89C4F4;
}
.form .field-actions .reject-button:hover {
  color: #EF4836;
}

.form .field-actions .accept-button:hover {
  color: #5cb85c;
}

.form .field-actions .correct-note-button:hover {
  color: #dd9c45;
}

.form .form-group-wrapper {
  padding: 5px 10px;
  border-left: 5px solid transparent;
}

.form .dirty-field {
  border-left: 5px solid #89C4F4;
  background-color: rgba(137, 196, 244, 0.1);
}

.form .rejected-field {
  border-left: 5px solid #EF4836;
  background-color: rgba(239, 72, 54, 0.1);
}

.form .corrected-field {
  border-left: 5px solid #FFCC00;
  background-color: rgba(255, 204, 0, 0.3);
}

button:focus {
  outline: none;
}
