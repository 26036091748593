.rodal-dialog {
  display: flex;
  flex-direction: column;
}

.rodal-dialog .header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.rodal-dialog .body {
  flex: 1;
  overflow-y: auto;
}

.rodal-dialog .footer {
  border-top: 1px solid #eee;
  margin-top: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.rodal-dialog .footer.left {
  justify-content: flex-start;
}
