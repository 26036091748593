@import url('https://fonts.googleapis.com/css?family=Roboto');

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

body div#root {
  width: 100%;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

button p,
button div {
  margin: 0!important;
}

button[type="button"] {
  appearance: none;
  -webkit-appearance: none;
}

/*button + button {
  margin-left: 20px;
}*/
