@import url('https://fonts.googleapis.com/css?family=Roboto');

.reactabular-table {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-collapse: collapse;
  border-spacing: 0;
}

.reactabular-table thead,
.reactabular-table tfoot {
  background-color: #efefef;
  color: #333;
}

.reactabular-table thead {
  border-bottom: 1px solid #e2e2e2;
}

.reactabular-table tfoot {
  border-top: 1px solid #e2e2e2;
}

.reactabular-table th,
.reactabular-table td {
  border-left: 1px solid #e2e2e2;
  border-width: 0 0 0 1px;
  margin: 0;
  overflow: visible;
  padding: 0.5em 1em;
}

.reactabular-table tbody {
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
}

.reactabular-table tbody tr:hover {
  background-color: #dbebf9;
  cursor: pointer;
}

.reactabular-table tbody tr.selected-row {
  background-color: #2e82e4;
  color: #fff;
}

.reactabular-table tbody tr.virtual-sample {
  opacity: 0.3;
}

.sort-container > .sort.sort-asc::after {
  content: '\25BC';
  padding-left: 0.5em;
}

.sort-container > .sort.sort-desc::after {
  content: '\25B2';
  padding-left: 0.5em;
}

.sort-container > .sort.sort-none::after {
  content: '\21D5';
  padding-left: 0.5em;
  font-size: 1em;
}

.sort-container > .sort-order {
  display: none;
}

.search-container > div {
  display: flex;
  align-items: center;
}

.search-container input,
.search-container select {
  padding: 0.5em 0.75em;
  font-size: 14px;
  line-height: 1.25em;
  border: 1px solid #aaaaaa;
  background-color: #fff;
  outline: none;
  border-image: none;
  height: 35px;
}

.search-container select {
  padding: 0.5em 0.25em;
  border-right: none;
  border-radius: 4px 0 0 4px;
  background-color: #f6f6f6;
}

.search-container input {
  border-radius: 0 4px 4px 0;
}

.search-container .icon-button {
  height: 25px;
  width: 25px;
  margin-left: 5px;
  color: #fff!important;
  background-color: #589ceb;
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out;
}

.search-container .icon-button:hover {
  opacity: 0.7;
  transform: none;
  color: #fff;
}
